import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-117197ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tiles-switch" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "text-left" }
const _hoisted_4 = { class: "tiles-switch__title" }
const _hoisted_5 = { class: "body-text-regular-12 lh-16 neutral--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_badge = _resolveComponent("tm-badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item[_ctx.itemKey] || i,
        class: _normalizeClass(_ctx.itemWrapClass)
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(["tiles-switch__item", [
          _ctx.itemClass,
          { 'tiles-switch__item--outline': _ctx.outline },
          { 'tiles-switch__item--active': _ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey] },
        ]]),
          type: "button",
          onClick: ($event: any) => (_ctx.onClick(item))
        }, [
          _renderSlot(_ctx.$slots, "item", _normalizeProps(_guardReactiveProps({
            item,
            index: i,
            active: _ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey],
          })), () => [
            _createElementVNode("span", {
              class: _normalizeClass(["d-flex", { 'flex-column': _ctx.contentColumn }])
            }, [
              _createVNode(_component_tm_icon, {
                class: _normalizeClass([
                _ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey] ? 'text-primary' : 'distinct--text',
                _ctx.contentColumn ? 'mb-10px' : 'mr-10px'
              ]),
                name: item.icon,
                "custom-size": _ctx.contentColumn ? '40px' : '20px'
              }, null, 8, ["class", "name", "custom-size"]),
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "title", {
                  title: item.title
                }, () => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", null, _toDisplayString(item.title), 1),
                    (item.badge)
                      ? (_openBlock(), _createBlock(_component_tm_badge, {
                          key: 0,
                          color: item.badge.color,
                          outline: item.badge.outline,
                          label: item.badge.label,
                          static: "",
                          class: "my-n1"
                        }, null, 8, ["color", "outline", "label"]))
                      : _createCommentVNode("", true)
                  ])
                ], true),
                _createElementVNode("div", _hoisted_5, _toDisplayString(item.description), 1)
              ])
            ], 2)
          ], true),
          (_ctx.modelValue && item[_ctx.itemKey] === _ctx.modelValue[_ctx.itemKey] && _ctx.$slots['active-footer'])
            ? _renderSlot(_ctx.$slots, "active-footer", _normalizeProps(_mergeProps({ key: 0 }, {
            item,
          })), undefined, true)
            : _createCommentVNode("", true)
        ], 10, _hoisted_2)
      ], 2))
    }), 128))
  ]))
}
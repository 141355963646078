
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import Container2fa from '@/components/views/auth/2fa/Container2fa.vue'
import TmTilesSwitch from '@/components/shared/TmTilesSwitch.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    TmTilesSwitch,
    TmButton,
    Container2fa,
  },
  setup() {
    const methods = [
      {
        type: 'authApp',
        icon: 'tmi-phone-lock',
        title: 'Authentication app',
        description: 'Retrieve codes from Google Authenticator app on your device.',
        badge: {
          label: 'Recommended',
          color: 'success',
          outline: true,
        },
        path: 'app',
      },
      {
        type: 'sms',
        icon: 'phone',
        title: 'Phone number',
        description: 'Receive a text message or a call to your mobile device when signing in.',
        path: 'phone',
      },
    ]
    const authMethodValue = ref(methods[0])

    const router = useRouter()
    const loadingStatus = ref(false)
    const clickContinueButton = () => {
      loadingStatus.value = true
      setTimeout(() => {
        loadingStatus.value = false
        router.push({ name: `auth.2fa.methods-${authMethodValue.value.path}` })
      }, 2000)
    }

    return {
      authMethodValue,
      methods,
      loadingStatus,
      clickContinueButton,
    }
  },
})


import { defineComponent } from 'vue'
import TmBadge from '@/components/shared/TmBadge.vue'

export default defineComponent({
  name: 'TmTilesSwitch',
  components: {
    TmBadge,
  },
  props: {
    modelValue: {
      type: Object,
    },
    contentColumn: {
      type: Boolean,
    },
    emitValue: {
      type: Boolean,
    },
    offset: {
      type: [Number, String],
    },
    label: {
      type: String,
    },
    itemKey: {
      type: String,
      default: 'title',
    },
    itemWrapClass: {
      type: String,
    },
    itemClass: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    outline: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const onClick = (val: Record<string, any>) => {
      if (props.emitValue) {
        context.emit('update:modelValue', val[props.itemKey])
      } else {
        context.emit('update:modelValue', val)
      }
    }

    return {
      onClick,
    }
  },
})
